/* Load missing polyfills - Most are loaded by babel-reset-env */
import "core-js/es/promise";
import "whatwg-fetch";
import "proxy-polyfill";
import elementClosest from "element-closest";
import smoothscroll from "smoothscroll-polyfill";

import React from "react";
import { render, unmountComponentAtNode } from "react-dom";
import EventEmitter from "eventemitter3";

import { CONFIGS, DEFAULTS } from "CONFIGS";

import __PACKAGE__ from "../package.json";
import { getDataAttribute, shadowDOM, externalStylesheet } from "./utils";

class BookingSearchBox {
	constructor() {
		// Expose client-side library to interact with widget
		window.WEB_CDN_BSB = this;

		this.version = __PACKAGE__.version;
		this.build = process.env.BUILD_ID;
		this.isInitializing = false;
		this.isInitialized = false;
		this._emitter = new EventEmitter();
		this._preinitializedEvents = [];
		this._reactRoot = null;
		this.init();
	}

	handlePreinitializedEvents() {
		while (this._preinitializedEvents.length) {
			const event = this._preinitializedEvents.shift();
			this._emitter.emit(event.eventName, event.payload);
		}
	}

	init() {
		// Set when widget is fully initialized - determined per view
		this._emitter.on("initialized", () => {
			this.isInitialized = true;
			this.handlePreinitializedEvents();
			this.onLoad();
		});

		if (!this.isInitializing || !this.isInitialized) {
			import("./index.scss");

			/* Initialize shadow dom */
			const [appDOM, reactRoot, reactDOM] = shadowDOM(__PACKAGE__.name);

			/**
			 * Get booking search box brand from data attribute or use default
			 * @category App Initialization
			 * @type {string}
			 * @default SWG
			 */
			const brand = getDataAttribute(
				appDOM,
				"brand",
				DEFAULTS.SELECTED_BRAND
			).toUpperCase();

			/* Save reference to reactRoot in order to be used to unmount */
			this._reactRoot = reactRoot;

			/* Initialize Element.closest() polyfill for IE11) */
			elementClosest(window);

			/* Initialize ScrollTo smooth behaviour polyfill */
			smoothscroll.polyfill();

			/* Retrieve app settings from data attributes / configuration */
			/**
			 * Get language from data attribute or use default
			 * @category App Initialization
			 * @type {string}
			 * @default en
			 */
			const lang = getDataAttribute(appDOM, "lang", DEFAULTS.LANGUAGE);

			/* Retrieve profile from data attributes / configuration */
			/**
			 * Get language from data attribute or use default
			 * @category App Initialization
			 * @type {string}
			 * @default en
			 */
			const profile = getDataAttribute(
				appDOM,
				"profile",
				DEFAULTS.PROFILE,
				"string-case-sensitive"
			);

			/**
			 * Get booking search box type from data attribute or use default
			 * @category App Initialization
			 * @type {string}
			 * @default PACKAGES
			 */
			const type = getDataAttribute(appDOM, "type", DEFAULTS.SELECTED_TYPE);

			/**
			 * Get booking search box mode from data attribute or use default
			 * @category App Initialization
			 * @type {string}
			 * @default SEARCH
			 */
			const mode = getDataAttribute(
				appDOM,
				"mode",
				DEFAULTS.SELECTED_MODE
			).toUpperCase();

			/**
			 * Get booking search box displayed tabs from data attribute or use default
			 * @category App Initialization
			 * @type {string}
			 * @default PACKAGES,FLIGHTS,HOTELS,CRUISES
			 */
			const tabs = getDataAttribute(appDOM, "tabs", DEFAULTS.SELECTED_TABS)
				.toUpperCase()
				?.split(",");

			/**
			 * Get SoftVoyage alias from data attribute or use default
			 * @category App Initialization
			 * @type {string}
			 * @default btd
			 */
			let ALIAS_BY_BRAND = DEFAULTS.SOFTVOYAGE.ALIAS;
			if (brand === "SOV") {
				ALIAS_BY_BRAND = DEFAULTS.SOFTVOYAGE.ALIAS_SOV;
			}
			const alias = getDataAttribute(appDOM, "alias", ALIAS_BY_BRAND);

			/**
			 * Get SoftVoyage code_ag from data attribute	 or use default
			 * @category App Initialization
			 * @type {string}
			 * @default rds
			 */
			let CODE_AG_BY_BRAND = DEFAULTS.SOFTVOYAGE.CODE_AG;

			if (brand === "VWQ") {
				CODE_AG_BY_BRAND = DEFAULTS.SOFTVOYAGE.CODE_AG_VWQ;
			}

			if (brand === "SOV") {
				CODE_AG_BY_BRAND = DEFAULTS.SOFTVOYAGE.CODE_AG_SOV;
			}
			const codeAg = getDataAttribute(appDOM, "codeAg", CODE_AG_BY_BRAND);

			/**
			 * Get SOV Flight API url from data attribute or use default
			 * @category App Initialization
			 * @type {string}
			 * @default https://flight.selloffvacations.com
			 */
			const sovFlightApi = getDataAttribute(
				appDOM,
				"sovFlightApi",
				SOV_FLIGHT_API
			);

			/**
			 * Get Infoservice API url from data attribute or use default
			 * @category App Initialization
			 * @type {string}
			 * @default https://infoservice.sunwingtravelgroup.com
			 */
			const infoserviceApi = getDataAttribute(
				appDOM,
				"infoserviceApi",
				INFOSERVICE_API
			);

			/**
			 * Get SV Handler API url from data attribute or use default
			 * @category App Initialization
			 * @type {string | string}
			 * @default https://svhandlerapi.sunwingtravelgroup.com
			 */
			const svHandlerApi = getDataAttribute(
				appDOM,
				"svHandlerApi",
				SV_HANDLER_API
			);

			/**
			 * Get SoftVoyage's Booking Path - Packages url from data attribute or use default
			 * @category App Initialization
			 * @type {string | string}
			 * @default https://book.sunwing.ca/cgi-bin/resultspackage-plus.cgi
			 */
			let PACKAGE_URL_BY_BRAND = SV_BP_PACKAGES_URL;

			if (brand === "VWQ") {
				PACKAGE_URL_BY_BRAND = SV_VWQ_BP_PACKAGES_URL;
			}

			if (brand === "SOV") {
				PACKAGE_URL_BY_BRAND = SV_SOV_BP_PACKAGES_URL;
			}
			const svBpPackagesUrl = getDataAttribute(
				appDOM,
				"svBpPackagesUrl",
				PACKAGE_URL_BY_BRAND
			);

			/**
			 * Get SoftVoyage's Booking Path - Flights url from data attribute or use default
			 * @category App Initialization
			 * @type {string | string}
			 * @default https://book.sunwing.ca/cgi-bin/results.cgi
			 */
			let FLIGHT_URL_BY_BRAND = SV_BP_FLIGHTS_URL;
			if (brand === "SOV") {
				FLIGHT_URL_BY_BRAND = SV_SOV_BP_FLIGHTS_URL;
			}
			const svBpFlightsUrl = getDataAttribute(
				appDOM,
				"svBpFlightsUrl",
				FLIGHT_URL_BY_BRAND
			);

			/**
			 * Get SoftVoyage's Booking Path - Hotels url from data attribute or use default
			 * @category App Initialization
			 * @type {string | string}
			 * @default https://shopping.sunwing.ca/cgi-bin/results-hotel.cgi
			 */
			const svBpHotelsUrl = getDataAttribute(
				appDOM,
				"svBpHotelsUrl",
				SV_BP_HOTELS_URL
			);

			/**
			 * Get SoftVoyage's Booking Path - Cruises url from data attribute or use default
			 * @category App Initialization
			 * @type {string | string}
			 * @default https://shopping.sunwing.ca/cgi-bin/resultscruise.cgi
			 */
			const svBpCruisesUrl = getDataAttribute(
				appDOM,
				"svBpCruisesUrl",
				SV_BP_CRUISES_URL
			);

			/**
			 * Get Sunwing.ca's Group Travel Page url from data attribute or use default
			 * @category App Initialization
			 * @type {string | string}
			 * @default https://www.sunwing.ca/en/grouptravel
			 */
			let groupTravelUrlEN = getDataAttribute(
				appDOM,
				"groupTravelUrlEN",
				GROUP_TRAVEL_URL_EN
			);
			if (brand === "SOV") {
				groupTravelUrlEN = getDataAttribute(
					appDOM,
					"groupTravelUrlSOV",
					SOV_GROUP_TRAVEL_URL_EN
				);
			}

			/**
			 * Get Sunwing.ca's Group Travel Page url from data attribute or use default
			 * @category App Initialization
			 * @type {string | string}
			 * @default https://www.sunwing.ca/fr/grouptravel
			 */
			let groupTravelUrlFR = getDataAttribute(
				appDOM,
				"groupTravelUrlFR",
				GROUP_TRAVEL_URL_FR
			);
			if (brand === "SOV") {
				groupTravelUrlFR = getDataAttribute(
					appDOM,
					"groupTravelUrlSOVFR",
					SOV_GROUP_TRAVEL_URL_FR
				);
			}

			/**
			 * Get Logging API url from data attribute or use default
			 * @category App Initialization
			 * @type {string | string}
			 * @default https://weblogging.sunwingtravelgroup.com/api/v1/
			 */
			const loggingApi = getDataAttribute(appDOM, "loggingApi", LOGGING_API);

			/**
			 * Get configuration for enabling Logging API from data attribute or use default
			 * @category App Initialization
			 * @type {string | string}
			 * @default "true"
			 */
			const enableLogging = getDataAttribute(
				appDOM,
				"enableLogging",
				ENABLE_LOGGING
			);

			/**
			 * Get stylesheet override url from data attribute or use default
			 * @category App Initialization
			 * @type {string | undefined}
			 * @default undefined
			 */
			const stylesheet = getDataAttribute(appDOM, "stylesheet", undefined);

			/* Load external stylesheet into shadow dom / dom when supplied */
			externalStylesheet(reactDOM, stylesheet);

			/* Listen to data layer update event */
			this._emitter.on("update-data-layer", dataLayer => {
				if (!window.dataLayer) window.dataLayer = [];
				window.dataLayer.push(dataLayer);
			});

			CONFIGS.initialize({
				sovFlightApi,
				infoserviceApi,
				svHandlerApi,
				svBpPackagesUrl,
				svBpFlightsUrl,
				svBpHotelsUrl,
				svBpCruisesUrl,
				groupTravelUrlEN,
				groupTravelUrlFR,
				loggingApi,
				enableLogging
			})
				.then(() => import("./App.js"))
				.then(({ default: App }) => {
					this.isInitializing = false;
					render(
						<App
							lang={lang}
							reactDOM={reactDOM}
							appRoot={reactRoot}
							brand={brand}
							type={type}
							mode={mode}
							tabs={tabs}
							alias={alias}
							codeAg={codeAg}
							emitter={this._emitter}
							profile={profile}
						/>,
						reactRoot
					);
				});
		}

		return this;
	}

	onLoad(callback) {
		if (callback && typeof callback === "function") {
			callback(this);
		}

		return this;
	}

	overrideDefaults(settings) {
		if (!this.isInitialized) {
			this._preinitializedEvents.push({
				eventName: "override-defaults",
				payload: settings
			});
			return;
		}

		try {
			if (this._emitter) {
				this._emitter.emit("override-defaults", settings);
			}
		} catch {
			console.error("WEB.CDB.BSB | Unable to emit event");
		}
	}

	reset() {
		if (this.isInitialized) {
			try {
				this.destroy();
				this.init();
			} catch (error) {
				console.error("WEB.CDB.BSB | Unable to unmount application", error);
			}
		}
	}

	destroy() {
		if (this.isInitialized) {
			try {
				if (unmountComponentAtNode(this._reactRoot)) {
					this.isInitialized = false;
					this._emitter.off("initialized");
					this._emitter.off("update-data-layer");
				} else {
					console.error("WEB.CDB.BSB | Unable to unmount application");
				}
			} catch (error) {
				console.error("WEB.CDB.BSB | Unable to unmount application", error);
			}
		}
	}
}

window.WEB_CDN_BSB = new BookingSearchBox();
