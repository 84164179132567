/* spell-checker: disable */
// Booking Search Box Configurations
const BRANDS = {
	SWG: "SWG",
	VWQ: "VWQ"
};

const TYPES = {
	PACKAGES: "PACKAGES",
	FLIGHTS: "FLIGHTS",
	HOTELS: "HOTELS",
	CRUISES: "CRUISES"
};

const MODES = {
	TABS: "TABS",
	SEARCH: "SEARCH",
	EDIT: "EDIT"
};

const DEFAULTS = {
	INFOSERVICE_API: "https://infoservice.sunwingtravelgroup.com",
	SV_HANDLER_API: "https://svhandlerapi.sunwingtravelgroup.com",
	SV_BP_PACKAGES_URL: "https://book.sunwing.ca/cgi-bin/resultspackage-plus.cgi",
	SV_VWQ_BP_PACKAGES_URL:
		"https://book.vacanceswestjetquebec.com/cgi-bin/handler.cgi",
	SV_BP_FLIGHTS_URL: "https://book.sunwing.ca/cgi-bin/results.cgi",
	SV_BP_HOTELS_URL: "https://shopping.sunwing.ca/cgi-bin/results-hotel.cgi",
	SV_BP_CRUISES_URL: "https://shopping.sunwing.ca/cgi-bin/resultscruise.cgi",
	GROUP_TRAVEL_URL_EN: "https://www.sunwing.ca/en/grouptravel",
	GROUP_TRAVEL_URL_FR: "https://www.sunwing.ca/fr/grouptravel",
	LOGGING_API: "https://weblogging.sunwingtravelgroup.com/api/v1/",
	ENABLE_LOGGING: true,
	CONTENTFUL_SPACE_RCL: "bjgonf6fglpm",
	RCL_APP_CODE: "BSB",
	SOFTVOYAGE: {
		ALIAS: "btd",
		CODE_AG: "rds",
		CODE_AG_VWQ: "vwq"
	},
	LANGUAGE: "en",
	PROFILE: undefined,
	SELECTED_BRAND: BRANDS.SWG,
	SELECTED_TYPE: TYPES.PACKAGES,
	SELECTED_MODE: MODES.SEARCH,
	SELECTED_TABS: "PACKAGES,FLIGHTS,HOTELS,CRUISES",
	PACKAGES: {
		ROOMS_OCCUPANCIES: {
			rooms: 1,
			adults: 2,
			children: [],
			infants: 0
		}
	}
};

// Configs / API / Endpoints
const CONFIGS = {
	_infoserviceApi: "",
	_svHandlerApi: "",
	_svBpPackagesUrl: "",
	_svBpFlightsUrl: "",
	_svBpHotelsUrl: "",
	_svBpCruisesUrl: "",
	_groupTravelUrlEN: "",
	_groupTravelUrlFR: "",
	_loggingApi: "",

	// DCIS
	DCIS_ENDPOINT: "/contentful",

	// Packages
	PACKAGES_GATEWAYS_ENDPOINT:
		"/search/gateway/group?lang={lang}&type=package&alias={alias}",
	PACKAGES_DESTINATIONS_ENDPOINT:
		"/search/destination/group?lang={lang}&type=package&alias={alias}&dep={gateways}",
	PACKAGES_HOTELS_ENDPOINT: "/hotels",

	// Flights - Round
	FLIGHTS_GATEWAYS_ENDPOINT:
		"/search/gateway/group?lang={lang}&type=flight&alias={alias}",
	FLIGHTS_DESTINATIONS_ENDPOINT:
		"/search/destination/group?lang={lang}&type=flight&alias={alias}&dep={gateways}",

	// Flights - One-way
	FLIGHTS_ONE_WAY_GATEWAYS_ENDPOINT:
		"/search/gateway/group?lang={lang}&type=flightoneway&alias={alias}",
	FLIGHTS_ONE_WAY_DESTINATIONS_ENDPOINT:
		"/search/destination/group?lang={lang}&type=flightoneway&alias={alias}&dep={gateways}",

	// Hotels
	HOTELS_DESTINATIONS_ENDPOINT: "/HotelDestinations/swg/{lang}/{query}",

	// Cruises
	CRUISES_GATEWAYS_ENDPOINT:
		"/search/gateway/group?lang={lang}&type=cruise&alias={alias}",
	CRUISES_DESTINATIONS_ENDPOINT:
		"/search/destination/group?lang={lang}&type=cruise&alias={alias}&dep={gateways}",

	initialize({
		infoserviceApi,
		svHandlerApi,
		svBpPackagesUrl,
		svBpFlightsUrl,
		svBpHotelsUrl,
		svBpCruisesUrl,
		groupTravelUrlEN,
		groupTravelUrlFR,
		loggingApi,
		enableLogging
	}) {
		this._infoserviceApi = infoserviceApi;
		this._svHandlerApi = svHandlerApi;
		this._svBpPackagesUrl = svBpPackagesUrl;
		this._svBpFlightsUrl = svBpFlightsUrl;
		this._svBpHotelsUrl = svBpHotelsUrl;
		this._svBpCruisesUrl = svBpCruisesUrl;
		this._groupTravelUrlEN = groupTravelUrlEN;
		this._groupTravelUrlFR = groupTravelUrlFR;
		this._loggingApi = loggingApi;
		this._enableLogging = enableLogging;

		return new Promise(resolve => resolve(this));
	},

	get LOGGER() {
		return {
			API: this._loggingApi,
			ENABLE: this._enableLogging
		};
	},
	get DCIS() {
		return this._infoserviceApi + this.DCIS_ENDPOINT;
	},
	get PACKAGES() {
		return {
			BOOKING_PATH: this._svBpPackagesUrl,
			GATEWAYS: this._infoserviceApi + this.PACKAGES_GATEWAYS_ENDPOINT,
			DESTINATIONS: this._infoserviceApi + this.PACKAGES_DESTINATIONS_ENDPOINT,
			HOTELS: this._svHandlerApi + this.PACKAGES_HOTELS_ENDPOINT
		};
	},
	get FLIGHTS() {
		return {
			BOOKING_PATH: this._svBpFlightsUrl,
			BOOKING_PATH_MOBILE: this._svBpFlightsMobileUrl,
			GATEWAYS: this._infoserviceApi + this.FLIGHTS_GATEWAYS_ENDPOINT,
			DESTINATIONS: this._infoserviceApi + this.FLIGHTS_DESTINATIONS_ENDPOINT,
			ONE_WAY_GATEWAYS:
				this._infoserviceApi + this.FLIGHTS_ONE_WAY_GATEWAYS_ENDPOINT,
			ONE_WAY_DESTINATIONS:
				this._infoserviceApi + this.FLIGHTS_ONE_WAY_DESTINATIONS_ENDPOINT
		};
	},
	get HOTELS() {
		return {
			BOOKING_PATH: this._svBpHotelsUrl,
			DESTINATIONS: this._svHandlerApi + this.HOTELS_DESTINATIONS_ENDPOINT
		};
	},
	get CRUISES() {
		return {
			BOOKING_PATH: this._svBpCruisesUrl,
			GATEWAYS: this._infoserviceApi + this.CRUISES_GATEWAYS_ENDPOINT,
			DESTINATIONS: this._infoserviceApi + this.CRUISES_DESTINATIONS_ENDPOINT
		};
	},
	get GROUPS() {
		return {
			EN: this._groupTravelUrlEN,
			FR: this._groupTravelUrlFR
		};
	}
};

const VALIDATIONS = {
	PACKAGES: {
		MAX_OCCUPANCIES: 10,
		MIN_ROOMS: 1,
		MAX_ROOMS: 10,
		MIN_ADULTS: 1,
		MAX_ADULTS: 10,
		// Includes infants
		MIN_CHILDREN: 0,
		MAX_CHILDREN: 9
	}
};

module.exports = {
	DEFAULTS,
	CONFIGS,
	BRANDS,
	TYPES,
	MODES,
	VALIDATIONS
};
