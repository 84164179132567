const getDataAttribute = (DOMElement, attributeName, defaultValue, parseData = "string") => {
	const _elDataset = DOMElement.dataset;
	let _attribute;

	// IE 10 Data Attribute Getter Fallbacks
	if (_elDataset !== undefined && _elDataset[attributeName]) {
		_attribute = _elDataset[attributeName];
	} else if (DOMElement.hasAttribute(`data-${attributeName}`)) {
		_attribute = DOMElement.getAttribute(`data-${attributeName}`);
	}

	// Format attribute
	try {
		if (_attribute !== undefined && _attribute.trim() !== "" && parseData) {
			switch (parseData.toLowerCase()) {
				case "json": {
					_attribute = JSON.parse(_attribute.replace(/'/g, '"'));
					break;
				}
				case "boolean": {
					_attribute = _attribute.toLowerCase() === "true";
					break;
				}
				case "int": {
					_attribute = parseInt(_attribute, 10);
					break;
				}
				case "string-boolean":
					if (_attribute.constructor === String) {
						_attribute = _attribute.toLowerCase();
					} else if (_attribute.constructor === Boolean) {
						_attribute = _attribute ? "true" : "false";
					}
					break;
				case "string-case-sensitive":
					break;
				default: {
					_attribute = _attribute.toLowerCase();
				}
			}
		}
	} catch (e) {
		_attribute = undefined;
		console.error(
			`getDataAttribute - Unable to parse (${parseData}) data - ${attributeName} - ${e}`
		);
	}

	if ((_attribute === undefined || _attribute === "") && defaultValue !== undefined) {
		_attribute = defaultValue;
	}

	return _attribute;
};

export default getDataAttribute;
