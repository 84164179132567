/* spell-checker: disable */
// Booking Search Box Configurations
const BRANDS = {
	SWG: "SWG",
	VWQ: "VWQ",
	SOV: "SOV",
};

const TYPES = {
	PACKAGES: "PACKAGES",
	FLIGHTS: "FLIGHTS",
	HOTELS: "HOTELS",
	CRUISES: "CRUISES",
	SMALLGROUPS: "SMALLGROUPS",
};

const MODES = {
	TABS: "TABS",
	SEARCH: "SEARCH",
	EDIT: "EDIT",
};

const DEFAULTS = {
	INFOSERVICE_API: "https://infoservice.sunwingtravelgroup.com",
	SV_HANDLER_API: "https://svhandlerapi.sunwingtravelgroup.com",
	SOV_FLIGHT_API: "https://flight.selloffvacations.com",

	// SWG
	SV_BP_PACKAGES_URL: "https://book.sunwing.ca/cgi-bin/resultspackage-plus.cgi",
	SV_BP_FLIGHTS_URL: "https://book.sunwing.ca/cgi-bin/results.cgi",
	SV_BP_HOTELS_URL: "https://shopping.sunwing.ca/cgi-bin/results-hotel.cgi",
	SV_BP_CRUISES_URL: "https://shopping.sunwing.ca/cgi-bin/resultscruise.cgi",

	// SOV
	SV_SOV_BP_FLIGHTS_URL: "https://shopping.selloffvacations.com/cgi-bin/mobile/results.cgi",

	// VWQ
	SV_VWQ_BP_PACKAGES_URL: "https://book.vacanceswestjetquebec.com/cgi-bin/handler.cgi",

	GROUP_TRAVEL_URL_EN: "https://www.sunwing.ca/en/grouptravel",
	GROUP_TRAVEL_URL_FR: "https://www.sunwing.ca/fr/grouptravel",
	SOV_GROUP_TRAVEL_URL_EN: "https://www.selloffvacations.com/en/group-deals",
	SOV_GROUP_TRAVEL_URL_FR: "https://www.selloffvacations.comfrn/group-deals",
	LOGGING_API: "https://weblogging.sunwingtravelgroup.com/api/v1/",
	ENABLE_LOGGING: true,
	CONTENTFUL_SPACE_RCL: "bjgonf6fglpm",
	RCL_APP_CODE: "BSB",
	SOFTVOYAGE: {
		ALIAS: "btd",
		ALIAS_SOV: "drv",
		CODE_AG: "rds",
		CODE_AG_VWQ: "vwq",
		CODE_AG_SOV: "drv",
	},
	LANGUAGE: "en",
	PROFILE: undefined,
	SELECTED_BRAND: BRANDS.SWG,
	SELECTED_TYPE: TYPES.PACKAGES,
	SELECTED_MODE: MODES.SEARCH,
	SELECTED_TABS: "PACKAGES,FLIGHTS,HOTELS,CRUISES",
	PACKAGES: {
		ROOMS_OCCUPANCIES: {
			rooms: 1,
			adults: 2,
			children: [],
			infants: 0,
		},
		SMALL_GROUPS_ROOMS_OCCUPANCIES: {
			rooms: 5,
			adults: 10,
			children: [],
			infants: 0,
		},
	},
	SMALL_GROUPS: {
		NAME: "small-groups",
		ALIAS: "grp",
	}
};

// Configs / API / Endpoints
const CONFIGS = {
	_sovFlightApi: "",
	_infoserviceApi: "",
	_svHandlerApi: "",
	_svBpPackagesUrl: "",
	_svBpFlightsUrl: "",
	_svBpHotelsUrl: "",
	_svBpCruisesUrl: "",
	_groupTravelUrlEN: "",
	_groupTravelUrlFR: "",
	_loggingApi: "",

	// DCIS
	DCIS_ENDPOINT: "/contentful",

	// ********SWG********
	// Packages
	PACKAGES_GATEWAYS_ENDPOINT: "/search/gateway/group?lang={lang}&type=package&alias={alias}",
	PACKAGES_DESTINATIONS_ENDPOINT:
		"/search/destination/group?lang={lang}&type=package&alias={alias}&dep={gateways}",
	// Hotels
	PACKAGES_HOTELS_ENDPOINT: "/hotels",
	// ************************************************************************************************

	// Flights - Round
	FLIGHTS_GATEWAYS_ENDPOINT: "/search/gateway/group?lang={lang}&type=flight&alias={alias}",
	FLIGHTS_DESTINATIONS_ENDPOINT:
		"/search/destination/group?lang={lang}&type=flight&alias={alias}&dep={gateways}",
	// Flights - One-way
	FLIGHTS_ONE_WAY_GATEWAYS_ENDPOINT:
		"/search/gateway/group?lang={lang}&type=flightoneway&alias={alias}",
	FLIGHTS_ONE_WAY_DESTINATIONS_ENDPOINT:
		"/search/destination/group?lang={lang}&type=flightoneway&alias={alias}&dep={gateways}",
	// ************************************************************************************************

	// Hotels
	HOTELS_DESTINATIONS_ENDPOINT: "/HotelDestinations/swg/{lang}/{query}",

	// Cruises
	CRUISES_GATEWAYS_ENDPOINT: "/search/gateway/group?lang={lang}&type=cruise&alias={alias}",
	CRUISES_DESTINATIONS_ENDPOINT:
		"/search/destination/group?lang={lang}&type=cruise&alias={alias}&dep={gateways}",
	// ************************************************************************************************

	// ********SOV********
	// Packages
	PACKAGES_SOV_GATEWAYS_ENDPOINT: "/gateways/{brand}/{lang}",
	PACKAGES_SOV_DESTINATIONS_ENDPOINT: "/destinations/{brand}/{lang}/{gateways}",
	// Flights
	FLIGHTS_SOV_GATEWAYS_AND_DESTINATIONS_ENDPOINT: "/selloff/flights/engine/gateway/",

	initialize({
		sovFlightApi,
		infoserviceApi,
		svHandlerApi,
		svBpPackagesUrl,
		svBpFlightsUrl,
		svBpHotelsUrl,
		svBpCruisesUrl,
		groupTravelUrlEN,
		groupTravelUrlFR,
		loggingApi,
		enableLogging,
	}) {
		this._sovFlightApi = sovFlightApi;
		this._infoserviceApi = infoserviceApi;
		this._svHandlerApi = svHandlerApi;
		this._svBpPackagesUrl = svBpPackagesUrl;
		this._svBpFlightsUrl = svBpFlightsUrl;
		this._svBpHotelsUrl = svBpHotelsUrl;
		this._svBpCruisesUrl = svBpCruisesUrl;
		this._groupTravelUrlEN = groupTravelUrlEN;
		this._groupTravelUrlFR = groupTravelUrlFR;
		this._loggingApi = loggingApi;
		this._enableLogging = enableLogging;

		return new Promise(resolve => resolve(this));
	},

	get LOGGER() {
		return {
			API: this._loggingApi,
			ENABLE: this._enableLogging,
		};
	},
	get DCIS() {
		return this._infoserviceApi + this.DCIS_ENDPOINT;
	},
	// SWG
	get PACKAGES() {
		return {
			BOOKING_PATH: this._svBpPackagesUrl,
			GATEWAYS: this._infoserviceApi + this.PACKAGES_GATEWAYS_ENDPOINT,
			DESTINATIONS: this._infoserviceApi + this.PACKAGES_DESTINATIONS_ENDPOINT,
			HOTELS: this._svHandlerApi + this.PACKAGES_HOTELS_ENDPOINT,
		};
	},
	// SOV
	get PACKAGES_SOV() {
		return {
			BOOKING_PATH: this._svBpPackagesUrl,
			GATEWAYS: this._svHandlerApi + this.PACKAGES_SOV_GATEWAYS_ENDPOINT,
			DESTINATIONS: this._svHandlerApi + this.PACKAGES_SOV_DESTINATIONS_ENDPOINT,
			HOTELS: this._svHandlerApi + this.PACKAGES_HOTELS_ENDPOINT,
		};
	},
	// SWG
	get FLIGHTS() {
		return {
			BOOKING_PATH: this._svBpFlightsUrl,
			BOOKING_PATH_MOBILE: this._svBpFlightsMobileUrl,
			GATEWAYS: this._infoserviceApi + this.FLIGHTS_GATEWAYS_ENDPOINT,
			DESTINATIONS: this._infoserviceApi + this.FLIGHTS_DESTINATIONS_ENDPOINT,
			ONE_WAY_GATEWAYS: this._infoserviceApi + this.FLIGHTS_ONE_WAY_GATEWAYS_ENDPOINT,
			ONE_WAY_DESTINATIONS: this._infoserviceApi + this.FLIGHTS_ONE_WAY_DESTINATIONS_ENDPOINT,
		};
	},
	// SOV
	get FLIGHTS_SOV() {
		return {
			BOOKING_PATH: this._svBpFlightsUrl,
			BOOKING_PATH_MOBILE: this._svBpFlightsMobileUrl,
			GATEWAYS: this._sovFlightApi + this.FLIGHTS_SOV_GATEWAYS_AND_DESTINATIONS_ENDPOINT,
		};
	},
	get HOTELS() {
		return {
			BOOKING_PATH: this._svBpHotelsUrl,
			DESTINATIONS: this._svHandlerApi + this.HOTELS_DESTINATIONS_ENDPOINT,
		};
	},
	get CRUISES() {
		return {
			BOOKING_PATH: this._svBpCruisesUrl,
			GATEWAYS: this._infoserviceApi + this.CRUISES_GATEWAYS_ENDPOINT,
			DESTINATIONS: this._infoserviceApi + this.CRUISES_DESTINATIONS_ENDPOINT,
		};
	},
	get GROUPS() {
		return {
			EN: this._groupTravelUrlEN,
			FR: this._groupTravelUrlFR,
		};
	},
	get SMALL_GROUPS() {
		return {
			EN: `${this._groupTravelUrlEN}/small`,
			FR: `${this._groupTravelUrlFR}/small`,
		};
	},
};

const VALIDATIONS = {
	PACKAGES: {
		MAX_OCCUPANCIES: 10,
		MIN_ROOMS: 1,
		MAX_ROOMS: 10,
		MIN_ADULTS: 1,
		MAX_ADULTS: 10,
		// Includes infants
		MIN_CHILDREN: 0,
		MAX_CHILDREN: 9,
	},
	SOV_PACKAGES: {
		MIN_ROOMS: 1,
		MAX_ROOMS: 6,
		MIN_ADULTS: 1,
		MAX_ADULTS: 6,
		MIN_CHILDREN: 0,
		MAX_CHILDREN: 4,
	},
	SOV_FLIGHTS: {
		MAX_ROOMS: 1,
		MIN_ADULTS: 1,
		MAX_ADULTS: 6,
		MIN_CHILDREN: 0,
		MAX_CHILDREN: 4,
		MAX_CHILD_AGE: 11,
	},
	SOV_SMALL_GROUP: {
		MIN_ROOMS: 5,
		MAX_ROOMS: 20,
		MIN_ADULTS: 10,
		MAX_ADULTS: 20,
	},
};

module.exports = {
	DEFAULTS,
	CONFIGS,
	BRANDS,
	TYPES,
	MODES,
	VALIDATIONS,
};
