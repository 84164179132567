import { v4 as uuid } from "uuid";
import LogManager from "./logger";
import getDataAttribute from "./get-data-attribute";
import UCMFetch from "./ucm-fetch";
import mockOrFetch from "./mock-or-fetch";
import { shadowDOM, externalStylesheet } from "./shadow-dom";
import { renderReactChildrenWithData } from "./render-react-children-with-data";

/**
 * Logger Instance Initial default if Logger has not been initialized
 * @constructor
 * @category Logging
 * @example
 * Logger.info("Event", "Message", PayloadObj)
 * Logger.warn("Event", "Message", PayloadObj)
 * Logger.fatal("Event", "Message", PayloadObj)
 * @see Initialized with {@link LoggerInstanceInit}
 */

// eslint-disable-next-line import/no-mutable-exports
let Logger = () => {
	console.warn("Logger has not been initialized");
};

/**
 * Initialize Logger Instance
 * @param apiUrl
 * @returns {LoggerInstance}
 * @constructor
 * @category Logging
 * @see {@link LoggerInstance}
 */
const LoggerInstanceInit = apiUrl => {
	/**
	 * Returns LoggerInstance after verifying apiUrl and other Constructor parameters
	 * @type {LogManager}
	 * @example
	 * LoggerInstance = new LogManager({
	 *  apiUrl: String,
	 *  apiKey: String,
	 *  applicationName: String,
	 *  applicationCode: String,
	 *  trackingId: String,
	 *  sendLogsPeriod: Number Milliseconds,
	 *  batchLogs: Bool
	 *  })
	 */
	Logger = new LogManager({
		apiUrl: `${apiUrl}log/batched/`,
		apiKey: "2aacb244-8098-4b34-9dd0-68a856fd4f75",
		trackingId: uuid(),
		applicationName: "Booking Search Box",
		applicationCode: "web.cdn.bsb",
		sendLogsPeriod: 2500,
		storageKeyPrefix: "booking-search-box-log-cache",
		batchLogs: true
	});

	return Logger;
};

export {
	LoggerInstanceInit,
	Logger,
	getDataAttribute,
	UCMFetch,
	mockOrFetch,
	shadowDOM,
	externalStylesheet,
	renderReactChildrenWithData
};
